import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePexRTC } from "@app/hooks/usePexip";
import clsx from "clsx";

import { Placement, PointerPlacement, Tooltip, Type } from "../../components/Tooltip/Tooltip";
import { ConnectionQuality, ConnectionQualityColors } from "../../types/ConnectionQuality";
import { isBetween } from "../../utils/isBetween";

import styles from "./ConnectionQualityIndicator.module.scss";

function mapPercentageToQuality(percentageLost: string) {
  const value = parseFloat(percentageLost) * 10.0;

  if (isBetween(value, 0, 2)) return ConnectionQuality.EXCELLENT;
  if (isBetween(value, 2, 6)) return ConnectionQuality.GREAT;
  if (isBetween(value, 6, 10)) return ConnectionQuality.OK;
  if (isBetween(value, 10, 20)) return ConnectionQuality.WEAK;
  if (value >= 20) return ConnectionQuality.TERRIBLE;

  return ConnectionQuality.DISCONNECTED;
}

function qualityColors(quality: number) {
  if (isBetween(quality, 4, 6)) return ConnectionQualityColors.GOOD;
  if (isBetween(quality, 2, 4)) return ConnectionQualityColors.WEAK;

  return ConnectionQualityColors.BAD;
}

export function ConnectionQualityIndicator() {
  const pexRTC = usePexRTC();
  const [quality, setQuality] = useState<ConnectionQuality>(ConnectionQuality.DISCONNECTED);
  const { t } = useTranslation("meeting_room");
  const firstRun = useRef(true);
  const [showSettingsError, setShowSettingsError] = useState(false);

  useEffect(() => {
    const interval = setInterval(async () => {
      const statistics = pexRTC?.getMediaStatistics();
      const percentageLost = statistics?.outgoing?.video["percentage-lost"];
      const newQuality = mapPercentageToQuality(percentageLost ?? "0");

      if (newQuality !== quality) {
        setQuality(newQuality);
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [quality, pexRTC]);

  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;

      return;
    }

    if (quality === ConnectionQuality.DISCONNECTED || quality === ConnectionQuality.TERRIBLE) {
      setShowSettingsError(true);
    } else {
      setShowSettingsError(false);
    }
  }, [quality]);

  return (
    <div className={styles.ConnectionQualityIndicatorWrapper}>
      {showSettingsError && (
        <div className={styles.ConnectionTooltip}>
          <Tooltip
            icon="signal"
            pointer={PointerPlacement.START}
            position={Placement.TOP}
            title={
              ConnectionQuality.TERRIBLE
                ? t("connection_quality_indicator.tooltip.weak_signal.title")
                : t("connection_quality_indicator.tooltip.lost_connection.title")
            }
            type={Type.ERROR}
            withClose
          >
            {ConnectionQuality.TERRIBLE
              ? t("connection_quality_indicator.tooltip.weak_signal.text")
              : t("connection_quality_indicator.tooltip.lost_connection.text")}
          </Tooltip>
        </div>
      )}
      <div className={styles.ConnectionQualityIndicator}>
        {Array.from(Array(5), (_, idx) => {
          const bar = `bar-${idx + 1}`;

          return (
            <div
              className={clsx(styles.bar, styles[bar], styles[qualityColors(quality)], {
                [styles.active]: quality > idx,
              })}
              key={bar}
            />
          );
        })}
      </div>
    </div>
  );
}
